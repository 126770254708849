import {
  Network, NETWORK_IDS, NETWORKS, POSTING_ACCOUNT_TYPE,
} from 'lib/constants';
import { NetworkIcon } from 'elements/NetworkIcon';
import { trackClickSocialMediaLinkEvent } from 'lib/hooks/useEventTracking';
import { User } from 'lib/types/users';
import { useApi } from 'lib/contexts/ApplicationState';
import { MailButton } from 'components/MailButton';
import { postingAccountToUrl } from 'lib/helpers';

interface Props {
  profileUser: User;
  showColor?: boolean;
}

export const SocialMediaRow = ({ profileUser, showColor }: Props) => {
  const API = useApi();

  const hasSocialMediaOrContact = () => {
    const {
      facebookUrl, instagramUrl, email, twitterUrl, tiktokUrl,
      youtubeUrl, behanceUrl, personalUrl, linkedinUrl, twitchUrl,
    } = profileUser;
    return !!(profileUser && ((email && profileUser?.publicSettings?.contactAllowed)
    || facebookUrl || instagramUrl || twitterUrl || tiktokUrl || youtubeUrl
    || behanceUrl || personalUrl || linkedinUrl || twitchUrl));
  };

  if (!hasSocialMediaOrContact()) {
    return null;
  }

  const networkButton = (network: Network, networkUrl: string) => {
    if (!networkUrl.length) {
      return null;
    }
    return (
      <a
        key={`network-${network.id}`}
        href={networkUrl}
        title={`open ${network.title}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackClickSocialMediaLinkEvent(
          API, network.name, profileUser.id,
        )}
      >
        <NetworkIcon
          network={network.id}
          showColor={showColor}
        />
      </a>
    );
  };

  return (
    <div id="profile-social-media" className="flex flex-wrap justify-center gap-4">
      {!!profileUser.email && !!profileUser?.publicSettings?.contactAllowed
        && (<MailButton user={profileUser} />)}
      {profileUser?.postingAccounts?.map((pa) => {
        if (!pa.showOnProfile || pa.type === POSTING_ACCOUNT_TYPE.manager) {
          return null;
        }
        const networkUrl = postingAccountToUrl(pa.network, pa.account);
        if (!networkUrl.length) {
          return null;
        }
        const network = NETWORKS[pa.network];
        return networkButton(network, networkUrl);
      })}
      {!!profileUser.personalUrl && networkButton(
        NETWORKS[NETWORK_IDS.personal], profileUser.personalUrl,
      )}
    </div>
  );
};
